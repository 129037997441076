<template>

<div class="share">

	<div class="share-title">Share</div>
	
	<div class="share-buttons">

		<a :href="links.facebook" target="_blank" class="share-buttons-item is-facebook"><app-icon s="facebookPlain" /><span>Facebook</span></a>
		<a :href="links.twitter" target="_blank" class="share-buttons-item is-twitter"><app-icon s="twitter" /><span>Twitter</span></a>
		<a :href="links.linkedin" target="_blank" class="share-buttons-item is-linkedin"><app-icon s="linkedinPlain" /><span>LinkedIn</span></a>
		<a :href="links.email" target="_blank" class="share-buttons-item is-email"><app-icon s="email" /><span>Email</span></a>
		<a href="#" v-on:click="onPrintClick" class="share-buttons-item is-print"><app-icon s="print" /><span>Print</span></a>

	</div>

</div>

</template>

<script>

export default {

	props: ['links'],

	methods: {

		onPrintClick: function() {

			window.print()

		}

	}

}

</script>

<style scoped>

.share-title {
	color: #1a234c;
	font-size: 16px;
	margin-bottom: 20px;
	text-align: center;
	text-transform: uppercase;
}

.share-buttons {
	display: flex;
	justify-content: center;
}

.share-buttons-item {
	margin: 0px 1px;
	width: 36px;
	height: 36px;
	line-height: 38px;
	text-align: center;
	color: #fff;
	font-size: 16px;
	border-radius: 50%;
}

.share-buttons-item span {
	display: none;
}

.share-buttons-item.is-facebook {
	background-color: #3A5998;
}

.share-buttons-item.is-twitter {
	background-color: #1DA1F2;
}

.share-buttons-item.is-linkedin {
	background-color: #1177B5;
}

.share-buttons-item.is-email {
	background-color: #1A234C;
}

.share-buttons-item.is-print {
	background-color: #1A234C;
}

</style>
